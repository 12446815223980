import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import MailList from "./components/MailList";


function App() {
  return (
    <Routes>
      <Route path="/dashboard" element={<Home />} />
      <Route path="/dashboard/sssbbbaaaccc" element={<MailList />} />
      <Route path='*' element={<Home />} />
    </Routes>
  );
}

export default App;

