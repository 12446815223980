import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

const MailList = () => {
  const [mails, setMails] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchMails = async () => {
      try {
        const response = await fetch('https://onlytalent.romsis.com/get/mails');
        if (!response.ok) {
          throw new Error('Veri çekme işlemi başarısız.');
        }
        const data = await response.json();
        setMails(data);
      } catch (error) {
        console.error('Mail verileri çekilirken hata oluştu:', error);
        setError('Veriler yüklenemedi. Lütfen daha sonra tekrar deneyin.');
      }
    };
    
    fetchMails();
  }, []);

  return (
    <div className="container mt-5">
      <h2>Kayıtlı Mailler</h2>
      {error && <p className="text-danger">{error}</p>}
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>

          </tr>
        </thead>
        <tbody>
          {mails.map((mail, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{mail.email}</td>

            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default MailList;
